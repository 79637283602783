import '../ActionButton';
import '../AlertMessage';
import { getTotalBondGrouping, totalBondGroupErrors } from '../JavaScriptFunctions';
import '../ModalComponent';
import '../Tooltip';
import template from './AllPaidPartialPayment.html';

class AllPaidPartialPayment extends HTMLElement {
    constructor() {
        super();
        this._hasTotalBondsFeatureFlag = null;
    }

    connectedCallback() {
        this.innerHTML = template;

        this._pageTools = new PageTools();
        this._jailId = null;
        this._model = null;
        this._tenantId = false;
        this._paymentTotal = 0;
        this._hays = 3;
        this._fulton = 4;
        this._felonyListHays = ['CF', 'F', 'FX', 'F1', 'F2', 'F3', 'FS', 'FU', 'Hybrid'];
        this._misdemeanorListHays = ['M', 'MA', 'MB', 'MC', 'MT', 'MU'];
        this._exceeds25ErrorText = 'Payments cannot be made on more than 25 offenses. Please reduce the number of offenses you are making a payment on.';
        this._noOffensesErrorText = 'You must select at least one offense.';
        this._nbTooltipMessage = 'NB means that an offense is Not Bondable.' +
            ' This could be because the bond type is not yet set, the bond amount is not yet set, or there is an active hold on the offense.';

        this._inmateName = this.querySelector('.stinmatename');
        this._inmateCard = this.querySelector('#inmatecell');
        this._tableBody = this.querySelector('#tablebody');
        this._paymentTotal = this.querySelector('#paymenttotal');
        this._errorMessage = this.querySelector('.errormessage');
        this._tooltip = this.querySelector('#nb-tooltip');
        this._backButton = this.querySelector('.stbackbutton');
        this._makePaymentButton = this.querySelector('.stbutton');

        this._modalComponent = this.querySelector('modal-component');
        this._closeDialogButton = this.querySelector('#close-modal-button');
        this._selectOffensesInstruction = this.querySelector('#select-offenses-instruction');

        this._makePaymentOnClick = this._makePaymentOnClick.bind(this);
        this._backButtonOnClick = this._backButtonOnClick.bind(this);
        this._getInmateInformation = this._getInmateInformation.bind(this);
        this._calculateInitialState = this._calculateInitialState.bind(this);
        this._haysInitialState = this._haysInitialState.bind(this);
        this._getTotalPayment = this._getTotalPayment.bind(this);
        this._gatherData = this._gatherData.bind(this);
        this._checkboxOnClick = this._checkboxOnClick.bind(this);
        this._setErrorMessage = this._setErrorMessage.bind(this);
        this._backButton.addEventListener('click', this._backButtonOnClick);
        this._makePaymentButton.addEventListener('click', this._makePaymentOnClick);

        this._closeDialog = this._closeDialog.bind(this);
        this._sortCharges = this._sortCharges.bind(this);
        this._sortChargesForHays = this._sortChargesForHays.bind(this);
        this._notBondableDialog = this._notBondableDialog.bind(this);
        this._getDegreeCode = this._getDegreeCode.bind(this);
        this._closeDialogButton.addEventListener('click', this._closeDialog);
    }

    disconnectedCallback() {
        this._backButton.removeEventListener('click', this._backButtonOnClick);
        this._makePaymentButton.removeEventListener('click', this._makePaymentOnClick);
        this._closeDialogButton.removeEventListener('click', this._closeDialog);
    }

    get hasTotalBondFeatureFlag() {
        const attr = 'has-total-bonds';
        return this.hasAttribute(attr) && this.getAttribute(attr).toLowerCase() === 'true';
    }

    set model(value) {
        this._jailId = value.JailId;
        this._tenantId = value.TenantId;
        this._model = value;
        this._inmateName.textContent = `${this._model.InmateLastName ?? ''}, ${this._model.InmateFirstName ?? ''} ${this._model.inmateMi ?? ''}`.trim();
        this._tenantName = this._model.TenantName;
        this._jmsBondTypeConfigurations = this._model.JmsBondTypes;
        this._getInmateInformation();
        this._loadTableCharges(value.AllPaidInmateCharges);
        this._setTypeTooltip();
        this._calculateInitialState();
        this._openOffensesIntructionsDialog();
    }
    
    _openOffensesIntructionsDialog() {
        if (this._tenantId !== this._hays)
            return;

        const rows = Array.from(this.querySelectorAll('#tablebody > tr')).filter(x => x.querySelector('.sttypecell').textContent !== 'NB');
        const offenseDegrees = Array.from(rows.map(x => x.querySelector('.stcheckbox').getAttribute('offensedegree')));

        if (offenseDegrees.some(x => this._felonyListHays.includes(x)) && offenseDegrees.some(y => this._misdemeanorListHays.includes(y))) {
            const title = 'Felony and Misdemeanor offenses cannot be combined in a payment.';
            this._selectOffensesInstruction.innerText = "Selected offenses must be either all Felony offenses or all Misdemeanor offenses."
                + "\n If Felony offenses are selected, Misdemeanor offenses cannot be selected."
                + "\n If Misdemeanor offenses are selected, Felony offenses cannot be selected."
                + "\n\n Offenses for this person will need to be paid in two separate payments.";
            this._modalComponent.openModal(title);
        }
    }

    _setTypeTooltip() {
        const display = this._model.AllPaidInmateCharges.some(x => x.BondTypeDisplayName === null);
        this._tooltip.hidden = !display;
        this._tooltip.title = this._nbTooltipMessage;
    }

    _getInmateInformation() {
        if (this._model.BookNumber === null || this._tenantId === null) {
            return;
        }
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'GET',
            `/PaymentPortal/InformationByBookNumber?bookNumber=${this._model.BookNumber}&tenantId=${this._tenantId}`,
            null,
            this._setInmateInfoCard.bind(this)
        );
    }

    _setInmateInfoCard(response, success) {
        if (!success) {
            return;
        }

        const inmate = JSON.parse(response);
        const mugshotContainer = document.createElement('td');
        const mugshots = document.createElement('mugshot-carousel');
        mugshots.tenantId = this._tenantId;
        mugshots.mugshots = inmate.mugshots;
        mugshotContainer.appendChild(mugshots);
        this._inmateCard.appendChild(mugshotContainer);

        const infoSection = document.createElement('td');
        infoSection.appendChild(createSpan("Booking Date: ", inmate.bookingDate));
        infoSection.appendChild(createSpan("Inmate ID: ", inmate.soNumber));
        infoSection.appendChild(createSpan("Booking #: ", inmate.bookingNumber));
        infoSection.appendChild(createSpan("DOB: ", inmate.dob));

        const raceGender = document.createElement('strong');
        raceGender.textContent = 'Race/Gender: ';
        infoSection.appendChild(raceGender);
        const racegenderData = document.createElement('span');
        racegenderData.textContent = `${inmate.race}/${inmate.gender} `;
        infoSection.appendChild(racegenderData);
        const raceGenderBr = document.createElement('br');
        infoSection.appendChild(raceGenderBr);
        const weightCell = document.createElement('strong');
        weightCell.textContent = "Weight: ";
        infoSection.appendChild(weightCell);
        const weightData = document.createElement('span');
        weightData.textContent = inmate.inmateWeight;
        infoSection.appendChild(weightData);
        const heightCell = document.createElement('strong');
        heightCell.textContent = " Height: ";
        infoSection.appendChild(heightCell);
        const heightSpan = document.createElement('span');
        heightSpan.textContent = `${inmate.inmateHeightFt}' ${inmate.inmateHeightIn}"`;
        infoSection.appendChild(heightSpan);
        const heightBR = document.createElement('br');
        infoSection.appendChild(heightBR);
        function createSpan(label, data) {
            const containingSpan = document.createElement('span');
            const bookingData = document.createElement('strong');
            bookingData.textContent = label;
            const bookingDataSpan = document.createElement('span');
            bookingDataSpan.textContent = data;
            const bookingBr = document.createElement('br');
            containingSpan.appendChild(bookingData);
            containingSpan.appendChild(bookingDataSpan);
            containingSpan.appendChild(bookingBr);
            return containingSpan;
        }
        this._inmateCard.appendChild(infoSection);
    }

    _sortChargesForHays(charges) {
        charges.sort((a, b) => {
            let degreeCodeA;
            let degreeCodeB;
            if (this._felonyListHays.includes(a.OffenseDegree)) {
                degreeCodeA = 'Felony';
            } else if (this._misdemeanorListHays.includes(a.OffenseDegree)) {
                degreeCodeA = 'Misdemeanor';
            } else {
                degreeCodeA = "Unknown";
                a.BondTypeDisplayName = null; //This is disabled
            }
            if (this._felonyListHays.includes(b.OffenseDegree)) {
                degreeCodeB = 'Felony';
            } else if (this._misdemeanorListHays.includes(b.OffenseDegree)) {
                degreeCodeB = 'Misdemeanor';
            } else {
                degreeCodeB = "Unknown";
                b.BondTypeDisplayName = null; //This is disabled
            }
            const aEnabled = a.BondTypeDisplayName !== null && a.RemainingBalance !== 0;
            const bEnabled = b.BondTypeDisplayName !== null && b.RemainingBalance !== 0;
            const aFullyPaid = a.BondTypeDisplayName && a.RemainingBalance === 0;
            const bFullyPaid = b.BondTypeDisplayName && b.RemainingBalance === 0;
            if (aEnabled !== bEnabled) {
                return bEnabled - aEnabled;
            } else if (aFullyPaid !== bFullyPaid) {
                return (bFullyPaid - aFullyPaid);
            } else if (degreeCodeA !== degreeCodeB) {
                return degreeCodeA.localeCompare(degreeCodeB);
            } else {
                return a.OffenseDescription.localeCompare(b.OffenseDescription);
            }
        });
    }

    _sortCharges(charges) {
        if (this.hasTotalBondFeatureFlag)
            return;
        else if (this._tenantId === this._hays)
            this._sortChargesForHays(charges);
        else {
            charges.sort((a, b) => {
                const aEnabled = a.BondTypeDisplayName !== null && a.RemainingBalance !== 0;
                const bEnabled = b.BondTypeDisplayName !== null && b.RemainingBalance !== 0;
                const aFullyPaid = a.BondTypeDisplayName && a.RemainingBalance === 0;
                const bFullyPaid = b.BondTypeDisplayName && b.RemainingBalance === 0;
                if (aEnabled !== bEnabled) {
                    return bEnabled - aEnabled;
                } else if (aFullyPaid !== bFullyPaid) {
                    return (bFullyPaid - aFullyPaid);
                } else {
                    return a.OffenseDescription.localeCompare(b.OffenseDescription);
                }
            });
        }
    }


    _getDegreeCode(offenseDegree) {
        let degreeCode = offenseDegree;

        if (this._tenantId === this._hays) {
            if (this._felonyListHays.includes(offenseDegree)) {
                degreeCode = 'Felony';
            }
            else if (this._misdemeanorListHays.includes(offenseDegree)) {
                degreeCode = 'Misdemeanor';
            }
            else {
                degreeCode = 'Unknown';
            }
        }

        return degreeCode;
    }

    _loadTableCharges(charges) {
        this._sortCharges(charges);

        let i = 1;
        for (const element of charges) {
            const isEvenRow = i % 2 === 0;
            const chargeRow = document.createElement('tr');
            chargeRow.setAttribute('chargeid', element.JmsChargeId);

            let degreeCode = this._getDegreeCode(element.OffenseDegree);

            const charge = document.createElement('td');
            charge.classList.add('stcell');
            charge.classList.toggle('stcell2', isEvenRow);
            if (this._tenantId === this._hays) {
                charge.textContent = `${degreeCode}: ${element.OffenseDescription}`;
            } else {
                charge.textContent = `${element.OffenseDescription}`;
            }
            chargeRow.appendChild(charge);

            const bondType = document.createElement('td');
            bondType.textContent = element.BondTypeDisplayName ? element.BondTypeDisplayName : "NB";
            bondType.classList.add('sttypecell');
            bondType.classList.toggle('stcell2', isEvenRow);
            chargeRow.appendChild(bondType);

            const balance = document.createElement('td');
            balance.textContent = element.RemainingBalance >= 0 && element.BondTypeDisplayName ? this._pageTools.formatNumberToDollarAmount(element.RemainingBalance) : "";
            balance.classList.add('stbalancecell');
            balance.classList.toggle('stcell2', isEvenRow);
            chargeRow.appendChild(balance);
            const checkBoxCell = document.createElement('td');
            checkBoxCell.classList.add('stcheckboxcell');
            checkBoxCell.classList.add('stcell');
            checkBoxCell.classList.toggle('stcell2', isEvenRow);
            const checkbox = document.createElement('input');
            checkbox.setAttribute('data-warrant-number', element.WarrantNumber !== 'null' && element.WarrantNumber ? element.WarrantNumber : '');
            checkbox.setAttribute('data-bond-type-id', element.BondTypeId);
            checkbox.setAttribute('bondtype', element.BondTypeDisplayName);
            checkbox.setAttribute('balance', String(element.RemainingBalance));
            checkbox.setAttribute('data-bond-type', element.BondType);
            checkbox.type = 'checkbox';
            checkbox.classList.add('stcheckbox');
            this._setupCheckBox(element, degreeCode, checkBoxCell, checkbox);
            checkBoxCell.appendChild(checkbox);
            chargeRow.appendChild(checkBoxCell);

            this._tableBody.appendChild(chargeRow);
            i++;
        }
    }

    _setupCheckBox(element, degreeCode, checkBoxCell, checkbox) {
        if (!element.BondTypeDisplayName || (this._tenantId === this._hays && degreeCode === 'Unknown') || element.RemainingBalance === 0) {
            checkbox.toggleAttribute('disabled', true);
            if (!element.BondTypeDisplayName || (this._tenantId === this._hays && degreeCode === 'Unknown')) {
                checkBoxCell.classList.toggle('relative');
                const div = document.createElement('div');
                div.classList.toggle('stclickablediv');
                div.addEventListener('click', () => this._notBondableDialog());
                checkBoxCell.appendChild(div);
            }
        } else {
            checkbox.setAttribute('offensedegree', String(element.OffenseDegree));
            checkbox.addEventListener('click', this._checkboxOnClick.bind(this));
        }
        checkbox.setAttribute('chargeid', element.JmsChargeId);
    }

    _notBondableDialog() {
        this._selectOffensesInstruction.innerText = this._nbTooltipMessage;
        this._modalComponent.openModal("NB offenses cannot be selected");
    }

    _checkboxOnClick(e) {
        this._clearErrorMessage();

        const checkBoxes = Array.from(this.querySelectorAll('.stcheckbox'));
        const selected = checkBoxes.filter((x) => x.checked && !x.disabled);
        const selectedOffenseDegrees = [];
        const selectedBondTypes = [];
        selected.forEach(x => selectedBondTypes.push(x.getAttribute('bondtype')));
        const currentSelected = e.target.getAttribute('bondtype');

        if (selected.length > 25) {
            this._setErrorMessage(this._exceeds25ErrorText);
        } else if (selected.length === 0) {
            this._setErrorMessage(this._noOffensesErrorText);
        } else if (selected.length !== 1 && selectedBondTypes.some(x => x !== currentSelected)) {
            e.target.toggleAttribute('selected', false);
            this._showBondTypeError();
        }

        if (this._tenantId === this._hays) {
            selected.forEach(x => selectedOffenseDegrees.push(x.getAttribute('offensedegree')));
            this._haysLogicOnClick();
        }

        if (this.hasTotalBondFeatureFlag) {
            const bondGroupings = getTotalBondGrouping(this._model.AllPaidInmateCharges, this._jmsBondTypeConfigurations, 'RemainingBalance');
            const group = bondGroupings.find(x => x[0].JmsChargeId === e.target.getAttribute('chargeid'));
            if (group) {
                const checkboxesInGroup = checkBoxes
                    .filter(x => x.getAttribute('chargeid') && group.some(y => y.JmsChargeId === x.getAttribute('chargeid')));
                this._checkAllChargesInGroup(checkboxesInGroup, e.target);
            }
            this._validateSelectedTotalBondGroups(checkBoxes, bondGroupings);
        }

        this._calculateTotalCharge();
    }

    _checkAllChargesInGroup(checkboxesInGroup, target) {
        const checkboxesExcludingTarget = checkboxesInGroup.filter(x => x.getAttribute('chargeid') !== target.getAttribute('chargeid'));
        for (let checkbox of checkboxesExcludingTarget)
            checkbox.checked = !checkbox.checked;
    }

    _validateSelectedTotalBondGroups(checkboxes, bondGroupings) {
        bondGroupings.forEach(group => {
            // We only care about charges that are checked
            const charges = group.filter(charge => checkboxes.some(cb => cb.checked && cb.getAttribute('chargeid') === charge.JmsChargeId));
            if (charges.length === 0)
                return;
            const totalBondErrorMessage = totalBondGroupErrors(charges, this._tenantId, this._jmsBondTypeConfigurations, 'RemainingBalance');
            if (totalBondErrorMessage !== null) {
                this._setErrorMessage(`There was an invalid total bond configuration. Please contact ${this._tenantName} if this problem persists.`);
            }
        });
    }

    _showBondTypeError() {
        this._errorMessage.textContent = "Offenses with different bond types cannot be processed in a single payment."
            + " Please ensure that all selected offenses share the same bond type (see the Type column) and try again.";
    }

    _clearErrorMessage() {
        this._errorMessage.textContent = "";
        this._makePaymentButton.toggleAttribute('disabled', false);
    }

    _setErrorMessage(value) {
        this._errorMessage.textContent = value;
        this._makePaymentButton.toggleAttribute('disabled', true);
    }

    _calculateTotalCharge() {
        const checkboxes = this.querySelectorAll('.stcheckbox');
        let paymentTotal = 0.00;
        for (const element of checkboxes) {
            if (element.checked) {
                paymentTotal = paymentTotal + parseFloat(element.getAttribute('balance'));
            }
        }
        this._paymentTotal.textContent = this._pageTools.formatNumberToDollarAmount(paymentTotal);
    }

    _haysLogicOnClick() {
        const rows = Array.from(this.querySelectorAll('#tablebody > tr'))
            .filter(x => x.querySelector('.sttypecell').textContent !== 'NB' &&
                !x.querySelector('.stcell').textContent.includes("Unknown:") &&
                x.querySelector('.stbalancecell').textContent !== "$0.00");
        const tds = rows.map(x => x.querySelector('.stcheckboxcell'));

        if (this._enabledUncheckCheckboxes(tds))
            return;

        const felonyChecked = this._findCheckedOffenseOnList(this._felonyListHays, tds);
        if (felonyChecked) {
            this._disableDifferentOffenseTypes(this._misdemeanorListHays, tds);
            return;
        }

        const misdemeanorChecked = this._findCheckedOffenseOnList(this._misdemeanorListHays, tds);
        if (misdemeanorChecked) {
            this._disableDifferentOffenseTypes(this._felonyListHays, tds);
        }
    }

    _findCheckedOffenseOnList(list, tds) {
        return list.includes(
            tds.find(td => td.querySelector('.stcheckbox').checked)
                .querySelector('.stcheckbox')
                .getAttribute('offenseDegree'));
    }

    _enabledUncheckCheckboxes(tds) {
        if (tds.every(td => !td.querySelector('.stcheckbox').checked)) {
            tds.forEach(x => {
                x.querySelector('.stclickablediv')?.remove();
                x.querySelector('.stcheckbox').toggleAttribute('disabled', false);
            });
            return true;
        }

        return false;
    }

    _disableDifferentOffenseTypes(listToDisable, tds) {
        const tdsListToDisable = tds.filter(x => listToDisable.includes(x.querySelector('.stcheckbox').getAttribute('offensedegree')));
        tdsListToDisable.forEach(td => {
            this._disableTd(td);
        });
    }

    _disableTd(td) {
        td.querySelector('.stcheckbox').toggleAttribute('disabled', true);

        const relativeIsToggled = td.classList.contains('relative');
        if (!relativeIsToggled)
            td.classList.toggle('relative');

        if (td.querySelector('.stclickablediv'))
            return;

        const div = document.createElement('div');
        div.classList.toggle('stclickablediv');
        div.addEventListener('click', this._openOffensesIntructionsDialog.bind(this));
        td.appendChild(div);
        td.querySelector('.stcheckbox').toggleAttribute('disabled', true);
    }

    _disableChildTotalBondCheckboxes(checkboxes) {
        if (!this.hasTotalBondFeatureFlag)
            return;
        const warrantNumberGroups = getTotalBondGrouping(this._model.AllPaidInmateCharges, this._jmsBondTypeConfigurations, 'RemainingBalance');
        warrantNumberGroups.forEach(group => {
            const chargeIds = group.map(y => y.JmsChargeId);
            const groupedCheckboxes = Array.from(checkboxes)
                .filter(y => chargeIds.includes(y.getAttribute('chargeid')));

            this._separateByBondGrouping(group);

            groupedCheckboxes.forEach(y => {
                // Data is ordered by BondAmount on the server side so the first one is the parent
                if (y.getAttribute('chargeid') !== group[0].JmsChargeId) {
                    y.setAttribute('disabled', true);
                }
            });
        });
    }

    _separateByBondGrouping(group) {
        const seperatorRow = document.createElement('tr');
        seperatorRow.classList.add("bond-group-separator");
        const referenceRow = this._tableBody.querySelector(`tr[chargeid="${group[group.length - 1].JmsChargeId}"]`);
        referenceRow.after(seperatorRow);
    }

    _setTotalBondInitialState(checkBoxes) {
        if (!this.hasTotalBondFeatureFlag)
            return;
        const warrantNumberGroups = getTotalBondGrouping(this._model.AllPaidInmateCharges, this._jmsBondTypeConfigurations, 'RemainingBalance');
        warrantNumberGroups.forEach(group => {
            const totalBondErrorMessage = totalBondGroupErrors(group, this._tenantId, this._jmsBondTypeConfigurations, 'RemainingBalance');
            const groupChargeIds = group.map(x => x.JmsChargeId);
            const groupCheckboxes = Array.from(checkBoxes).filter(x => groupChargeIds.includes(x.getAttribute('chargeid')));
            groupCheckboxes.forEach(x => {
                x.toggleAttribute('checked', !totalBondErrorMessage);
            });
        });
    }

    _calculateInitialState() {
        const checkBoxes = this.querySelectorAll('.stcheckbox');
        const bondTypes = [];
        let mismatched = true;

        for (let element of checkBoxes) {
            const bondType = element.getAttribute('bondtype');
            if (bondType)
                bondTypes.push(bondType);
        }
        this._disableChildTotalBondCheckboxes(checkBoxes);
        
        if (bondTypes.length > 0) {
            const areSame = bondTypes.every(x => x === bondTypes[0]);
            if (areSame) {
                Array.from(checkBoxes)
                    .filter(x => x.getAttribute('bondtype') === bondTypes[0])
                    .filter(x => x.getAttribute('balance') != 0)
                    .forEach(x => x.toggleAttribute('checked', true));
            };

            if (areSame && this.hasTotalBondFeatureFlag) {
                this._setTotalBondInitialState(checkBoxes);
            }
            mismatched = false;
        };
        
        if (this._tenantId === this._fulton) {
            this._conditionalLayoutText(mismatched);
        }
        if (this._tenantId === this._hays) {
            this._haysInitialState();
        }
        this._calculateTotalCharge();

        // Button starts out disabled in the HTML so we need to enable it if anything was auto checked.
        if (Array.from(checkBoxes).some(x => x.hasAttribute('checked'))) {
            this._makePaymentButton.toggleAttribute('disabled', false);
        }
    }

    _haysInitialState() {
        const checkBoxes = this.querySelectorAll('.stcheckbox');
        const allDegrees = [];

        for (let element of checkBoxes) {
            const offenseDegree = element.getAttribute('offensedegree');
            if (this._felonyListHays.includes(offenseDegree))
                allDegrees.push("Felony");
            if (this._misdemeanorListHays.includes(offenseDegree))
                allDegrees.push("Misdemeanor");
        };

        if (allDegrees.length > 1) {
            let mismatched = false;
            const differentDegrees = allDegrees.some(x => x !== allDegrees[0]);
            if (differentDegrees) {
                Array.from(checkBoxes)
                    .forEach(x => x.toggleAttribute('checked', false));
                mismatched = true;
            }
            this._conditionalLayoutText(mismatched);
        }
    }

    _conditionalLayoutText(mismatched) {
        if (mismatched) {
            document.querySelector('p.stinstructions').innerHTML = 'Please select the offenses that you wish to pay. When ready, click the <em>Make a Payment</em> button. If any offenses you wish to pay are not eligible, please contact ' + this._model.TenantName + ' jail for more information.';
        }
    }

    _validatePayments() {
        const checkBoxes = this.querySelectorAll('.stcheckbox');
        const selected = Array.from(checkBoxes).filter((x) => x.checked);
        if (selected.length > 25) {
            this._setErrorMessage(this._exceeds25ErrorText);
            return false;
        }

        if (selected.length === 0) {
            this._setErrorMessage(this._noOffensesErrorText);
            return false;
        }

        const differentTypesSelected = selected.some(x => x.getAttribute('bondtype') !== selected[0].getAttribute('bondtype'));
        if (differentTypesSelected) {
            this._showBondTypeError();
            return false;
        }
        return true;
    }

    _makePaymentOnClick() {
        if (!this._validatePayments()) {
            return;
        }

        this._pageTools.toggleTriggers(this);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'POST',
            `/PaymentPortal/SubmitPayment`,
            this._gatherData(),
            this._onMakePaymentCallback.bind(this)
        );
    }

    _gatherData() {
        const selectedChargeIds = [];
        this.querySelectorAll(".stcheckbox").forEach(x => {
            if (x.checked) {
                selectedChargeIds.push(x.getAttribute("chargeId"));
            }
        });

        const obj = {
            TenantId: this._model.TenantId,
            PlcCode: this._model.PlcCode,
            BookingNumber: this._model.BookNumber,
            SoNumber: this._model.SoNumber,
            PaymentTotal: this._getTotalPayment(),
            JailId: this._model.JailId,
            Charges: this._model.AllPaidInmateCharges
                .filter(x => selectedChargeIds.includes(x.JmsChargeId))
                .map((x, i) => ({
                    JmsChargeId: x.JmsChargeId,
                    PaymentAmount: x.RemainingBalance,
                    IsPayorInformationRequired: x.IsPayorInformationRequired,
                    IsAdditionalPayorInfoRequired: x.IsAdditionalPayorInfoRequired,
                    BondTypeId: x.BondTypeId
                }))
        };

        return obj;
    }

    _getTotalPayment() {
        const selected = this.querySelectorAll('.stcheckbox');
        let total = 0;
        selected.forEach(x => {
            if (x.checked) {
                total += Number(x.getAttribute('balance'));
            }
        });
        return total;
    }

    _onMakePaymentCallback(response, success) {
        if (!success) {
            this._pageTools.toggleTriggers(this, false);
            this._errorMessage.textContent = '';
            if (response.includes('Felony and Misdemeanor offenses cannot be combined in a payment')) {
                this._openOffensesIntructionsDialog();
                return;
            }
            else if (response.includes('The payment amount exceeds the maximum of')) {
                this._selectOffensesInstruction.innerText = response;
                this._modalComponent.openModal("Payment Amount Exceeds Maximum Allowed");
                this._makePaymentButton.toggleAttribute('disabled', true);
                return;
            }
            this._setErrorMessage(response);
            return;
        }
        //using regex to remove the extra quotation marks from the Guid returned from the controller
        const validationCode = response.replace(/[^a-zA-Z0-9\- ]/g, '');

        this._pageTools.redirectToUrl(`/PaymentPortal/PayorInformation?plcCode=${this._model.PlcCode}&payment=${validationCode}&soNumber=${this._model.SoNumber}&jailId=${this._jailId}`);

    }

    _backButtonOnClick() {
        this._pageTools.toggleTriggers(this);
        this._pageTools.redirectToUrl(`/PaymentPortal/?plc-code=${this._model.PlcCode}`);
    }

    _closeDialog() {
        this._modalComponent.closeModal();
    }
}
customElements.define('allpaid-partial-payment', AllPaidPartialPayment);