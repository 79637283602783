import { dollarFieldFormatter } from '../BootstrapTableExtensions.js';
import { initializeHtmlElement } from '../HTMLElementExtensions';

class BookoutQueueGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-application-grid id="bookout-queue"></bootstrap-application-grid>',
            [],
            []
        );
        this._tableColumns = [];
        this._tableConfig = {};
        this._bootstrapApplicationGrid = this.shadowRoot.querySelector('bootstrap-application-grid');
        this._pageTools = new PageTools();

        this._bookoutButtonOnClick = this._bookoutButtonOnClick.bind(this);
        this._cancelButtonOnClick = this._cancelButtonOnClick.bind(this);
    }

    get _bookoutApplicationFlag() {
        return this.hasAttribute('bookout-Application-flag') &&
            this.getAttribute('bookout-Application-flag').toLowerCase() === 'true';
    }

    get _cancelApplicationFlag() {
        return this.hasAttribute('cancel-Application-flag') &&
            this.getAttribute('cancel-Application-flag').toLowerCase() === 'true';
    }

    get _applicationRefreshTempFlag() {
        return this.hasAttribute('application-refreshTemp-flag') &&
            this.getAttribute('application-refreshTemp-flag').toLowerCase() === 'true';
    }

    connectedCallback() {
        this._compileColumns();
        this._createTableConfig();
        this._bootstrapApplicationGrid.initBootstrapApplicationGrid(this._tableConfig);
        this._refreshEnabled();
    }

    _bookoutFormatter(value, row, index, field) {
        return `<div class='d-flex justify-content-center'><action-button class='btn btn-primary' trigger bookout>Bookout</action-button></div>`;
    }

    _cancelFormatter(value, row, index, field) {
        return `<div class='d-flex justify-content-center'><action-button class='btn btn-danger' trigger cancel>Cancel</action-button></div>`;
    }

    _compileColumns() {
        this._tableColumns = [
            {
                title: 'Inmate',
                field: 'InmateFullNameRev',
                class: 'InmateFullNameRev',
                type: 'Text',
                escape: true
            },
            {
                title: 'SO Number',
                field: 'SONumber',
                class: 'SONumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Location',
                field: 'LocationNode',
                class: 'LocationNode',
                type: 'Text',
                escape: true
            },
            {
                title: 'Booking Number',
                field: 'BookNumber',
                class: 'BookNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Bond Type',
                field: 'BondTypeDisplayName',
                class: 'BondTypeDisplayName',
                type: 'Text',
                escape: true,
                formatter: this._bootstrapApplicationGrid.bondTypeFormatter.bind(this)
            },
            {
                title: 'Total Bond Amount',
                field: 'TotalBondAmt',
                class: 'TotalBondAmt',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Total Bond Fees',
                field: 'TotalCountyBondFees',
                class: 'TotalCountyBondFees',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Time Stamp',
                field: 'TimeStamp',
                class: 'TimeStamp',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapApplicationGrid.dateFormatter.bind(this)
            },
            {
                title: 'Bond Company',
                field: 'BondCompanyName',
                class: 'BondCompanyName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Jail ID',
                field: 'JailID',
                class: 'd-none JailID',
                type: 'Number',
                escape: true,
                printIgnore: true
            },
            {
                title: 'Bond ApplicationID',
                field: 'BondApplicationID',
                class: 'd-none BondApplicationID',
                type: 'Text',
                escape: true,
                printIgnore: true
            },
            {
                title: '',
                field: "bookoutPlaceholder",
                type: 'SafeHTML',
                searchable: false,
                escape: false,
                formatter: this._bookoutFormatter,
                printIgnore: true,
                class: this._bookoutApplicationFlag ? '' : 'd-none',
                events: { 'click [bookout]': this._bookoutButtonOnClick }
            },
            {
                title: '',
                field: "bookoutCancelPlaceholder",
                type: 'SafeHTML',
                searchable: false,
                escape: false,
                formatter: this._cancelFormatter,
                printIgnore: true,
                class: this._cancelApplicationFlag ? '' : 'd-none',
                events: { 'click [cancel]': this._cancelButtonOnClick }
            }
        ];
    }

    _createTableConfig() {
        this._tableConfig = {
            uniqueId: 'BondApplicationID',
            tableColumns: this._tableColumns,
            print: true,
            export: true,
            pageSize: 20,
            mainTableHeight: 600,
            exportFilename: 'Book-Out-Queue',
            url: '/BookOutQueue/ReviewQ_Read',
            httpMethod: 'POST',
            restoreFilters: true,
            tableName: 'BookOutQueue',
            tableClasses: 'table-striped',
            paginationDetails: true,
            toolbar: true,
            tableAlertMessage: true,
            columnFilters: true,
            sortable: true
        };
    }

    _refreshEnabled() {
        if (this._applicationRefreshTempFlag) {
            this._bootstrapApplicationGrid.triggerRefresh();
        }
    }

    _bookoutButtonOnClick(e, value, row, index) {
        this._pageTools.toggleTriggers(this._bootstrapApplicationGrid._bootstrapTable.shadowRoot, true);
        this._pageTools.redirectToUrl(`/BookOutQueue/ProcessReview/${row.BondApplicationID}`);
    }

    _cancelButtonOnClick(e, value, row, index) {
        this._pageTools.toggleTriggers(this._bootstrapApplicationGrid._bootstrapTable.shadowRoot, true);
        this._pageTools.redirectToUrl(`/Cancel/FinishCancel/${row.BondApplicationID}`);
    }
}

customElements.define('bookout-queue-grid', BookoutQueueGrid);