import fontawesome from "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-table/dist/bootstrap-table";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import bootstrap from "../scss/bootstrap-custom.scss";
import "./ActionButton";
import "./AlertMessage";
import "./Application/ChargesUnavailableDialog";
import { createColumn, dollarFieldFormatter, generateAndShowDetailTable } from "./BootstrapTableExtensions";
import template from "./CompleteApplication.html";
import bondWorkFlow from "./Enumerations/BondWorkflow.js";
import feeCategory from "./Enumerations/FeeCategory";
import paymentFeeDestination from "./Enumerations/PaymentFeeDestination";
import { initializeHtmlElement } from "./HTMLElementExtensions";
import "./InmateInformation";
import states from "./States";

class CompleteApplication extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ["container"]);
        this._pageTools = new PageTools();
        this._bookNumber = null;
        this._detailsObject = null;
        this._bondHasFee = false;
        this._inmateInformation = this.shadowRoot.getElementById("inmate-information");
        this._titleElement = this.shadowRoot.getElementById("title");
        this._alert = this.shadowRoot.getElementById("modal-alert-view");
        this._inmateFullNameInput = this.shadowRoot.getElementById("inmate-full-name");
        this._soNumberInput = this.shadowRoot.getElementById("so-number");
        this._dateOfBirthInput = this.shadowRoot.getElementById("date-of-birth");
        this._raceGenderInput = this.shadowRoot.getElementById("race-gender");
        this._table = this.shadowRoot.getElementById("table");
        this._cancelButton = this.shadowRoot.getElementById("cancel-button");
        this._agreeButton = this.shadowRoot.getElementById("agree-button");
        this._agreeButtonText = this.shadowRoot.getElementById("agree-button-text");
        this._legalese = this.shadowRoot.getElementById("legalese");
        this._convenienceFeeWarning = this.shadowRoot.getElementById("convenience-fee-warning");
        this._paymentGatewayNameElement = this.shadowRoot.getElementById("payment-gateway-name");
        this._alertMessage = this.shadowRoot.getElementById("alert-message");
        this._chargesUnavailableModal = this.shadowRoot.getElementById("charges-unavailable-dialog");
        this._confirmationCheckboxContainer = this.shadowRoot.getElementById("confirmation-checkbox-container");
        this._confirmationCheckbox = this.shadowRoot.getElementById("confirm-checkbox");

        this._cancelButtonOnclick = this._cancelButtonOnclick.bind(this);
        this._agreeButtonOnclick = this._agreeButtonOnclick.bind(this);
        this._setAgreeButton = this._setAgreeButton.bind(this);
        this._formatTenantLegaleseText = this._formatTenantLegaleseText.bind(this);
    }

    get _type() {
        const type = this.getAttribute("type");
        return type === bondWorkFlow.electronicSurety.string || type === bondWorkFlow.manualSurety.string
            ? bondWorkFlow.surety.string
            : type;
    }

    get _tenantName() {
        return this.getAttribute("tenantname");
    }

    get _username() {
        return this.getAttribute("username");
    }

    get _userBondCompany() {
        return this.getAttribute("bondcompany");
    }
    
    get _hasConvenienceFee() {
        return this.getAttribute("hasconveniencefee") === "true";
    }

    get _paymentGatewayName() {
        return this.getAttribute("paymentgatewayname");
    }

    get _errorMessage() {
        return this.getAttribute("error-message");
    }

    get _bondTypeDisplayName() {
        return this.getAttribute("display-name");
    }

    get _collateralAmount() {
        return this.getAttribute("collateral-amount");
    }

    get _reviewApplication() {
        const attr = "review-application";
        return this.hasAttribute(attr) && this.getAttribute(attr) === "true";
    }

    get _bondCompanyMugshotVerifyFeatureFlag() {
        const attr = "data-bond-company-mugshot-verify";
        return this.hasAttribute(attr) && this.getAttribute(attr).toLowerCase() === "true";
    }

    get _tenantStateFullName() {
        const stateAbbreviation = this.getAttribute("tenant-state").toUpperCase();
        return states.find(x => x.abbreviation === stateAbbreviation).name;
    }

    get _userIsCounty() {
        return this.hasAttribute("user-is-county") &&
            this.getAttribute("user-is-county").toLowerCase() === "true";
    }
    
    get _hasHideMugshotsForJailAccounts() {
        return this.hasAttribute("has-hide-mugshot-for-jail-accounts") &&
            this.getAttribute("has-hide-mugshot-for-jail-accounts").toLowerCase() === "true";
    }
    get _hasHideMugshotsForCompanyAccounts() {
        return this.hasAttribute("has-hide-mugshot-for-company-accounts") &&
            this.getAttribute("has-hide-mugshot-for-company-accounts").toLowerCase() === "true";
    }

    set model(value) {
        this._updateHtml(value);
        this._tenantLegalese = value.AttorneyDetails ? value.AttorneyDetails[0].TenantLegalese : "";
        const countyPaymentObj = value.Bond.Payments.find(x => x.PaymentFeeDestination === paymentFeeDestination.county.value) || {};
        const ebcaPaymentObj = value.Bond.Payments.find(x => x.PaymentFeeDestination === paymentFeeDestination.genesis.value) || {};
        const countyFee = countyPaymentObj.Amount || 0;
        const ebcaFee = ebcaPaymentObj.Amount || 0;
        let combinedModel;
        switch (this._type) {
            case bondWorkFlow.ownerSurety.string:
                combinedModel = value.OwnerDetails.map(ownerDetails =>
                    ({
                        BondApplicationDetailID: ownerDetails.BondApplicationDetailID,
                        OwnerDetails: ownerDetails
                    })
                );
                this._setToolTipAndLegaleseForSuretyTypes(countyFee,
                    value.Bond.TotalBondAmt,
                    ebcaFee,
                    value.Bond.BondApplicationFees,
                    this._computeCollateralAmount(value));
                this._setConfirmationCheckboxState();
                break;
            case bondWorkFlow.companyAttorney.string:
                combinedModel = value.AttorneyDetails.map(attorneyDetails =>
                    ({
                        BondApplicationDetailID: attorneyDetails.BondApplicationDetailID,
                        AttorneyDetails: attorneyDetails
                    })
                );
                this._setToolTipAndLegaleseForCompanyAttorney(countyFee,
                    value.Bond.TotalBondAmt,
                    ebcaFee,
                    value.Bond.BondApplicationFees);
                this._setConfirmationCheckboxState();
                break;
            case bondWorkFlow.surety.string:
                combinedModel = value.SuretyDetails.map(suretyDetails =>
                    ({
                        BondApplicationDetailID: suretyDetails.BondApplicationDetailID,
                        SuretyDetails: suretyDetails
                    })
                );
                this._setToolTipAndLegaleseForSuretyTypes(countyFee,
                    value.Bond.TotalBondAmt,
                    ebcaFee,
                    value.Bond.BondApplicationFees,
                    this._computeCollateralAmount(value));
                this._setConfirmationCheckboxState();
                break;
            default:
                combinedModel = value.CountyDetails.map(countyDetails =>
                    ({
                        BondApplicationDetailID: countyDetails.BondApplicationDetailID,
                        CountyDetails: countyDetails
                    })
                );
                this._setToolTipAndLegaleseForCountyTypes(value.Bond.TotalBondAmt,
                    value.Bond.BondApplicationFees,
                    value.Bond.BondAmountModifier,
                    this._computeCollateralAmount(value));
        }
        this._setAlertMessage();
        this._loadTableData(combinedModel);
    }

    connectedCallback() {
        if (this._type === bondWorkFlow.ownerSurety.string)
            this._detailsObject = "OwnerDetails";
        else if (this._type === bondWorkFlow.surety.string)
            this._detailsObject = "SuretyDetails";
        else if (this._type === bondWorkFlow.companyAttorney.string)
            this._detailsObject = "AttorneyDetails";
        else 
            this._detailsObject = "CountyDetails";
        
        this._chargesUnavailableModal.type = this._type;
        this._cancelButton.addEventListener("click", this._cancelButtonOnclick);
        this._agreeButton.addEventListener("click", this._agreeButtonOnclick);
        this._confirmationCheckbox.addEventListener("click", this._setAgreeButton);
        this._inmateInformation.userIsCounty = this._userIsCounty;
        this._inmateInformation.hasHideMugshotsForJailAccounts = this._hasHideMugshotsForJailAccounts;
        this._inmateInformation.hasHideMugshotsForCompanyAccounts = this._hasHideMugshotsForCompanyAccounts;
    }

    disconnectedCallback() {
        this._cancelButton.removeEventListener("click", this._cancelButtonOnclick);
        this._agreeButton.removeEventListener("click", this._agreeButtonOnclick);
        this._confirmationCheckbox.removeEventListener("click", this._setAgreeButton);
    }

    _updateHtml(model) {
        this._bondApplicationId = model.Bond.BondApplicationID;
        this._inmateInformation.loadInmateInformationByBondApplicationId(model.Bond.BondApplicationID);
    }

    _loadTableData(model) {
        const table = $(this._table);
        let columns = [];
        columns.push(createColumn("Degree", `${this._detailsObject}.OffenseDegree`));
        columns.push(createColumn("Offense", `${this._detailsObject}.OffenseDesc`));
        columns.push(createColumn("Bond Number", `${this._detailsObject}.BondApplicationDetailID`));
        columns.push(createColumn("Bond Amount", `${this._detailsObject}.BondAmt`, undefined, dollarFieldFormatter));

        switch (this._type) {
            case bondWorkFlow.attorney.string:
            case bondWorkFlow.companyAttorney.string:
                columns.push(createColumn("Attorney Name", `${this._detailsObject}.AttorneyName`));
                columns.push(createColumn("Bar Number", `${this._detailsObject}.BarNumber`));
                break;
            case bondWorkFlow.cash.string:
                columns.push(createColumn("Receipt Number", `${this._detailsObject}.CashReceiptNumber`));
                break;
            case bondWorkFlow.pr.string:
                columns.push(createColumn("Nearest Relative", `${this._detailsObject}.NearestRelativeName`));
                columns.push(createColumn("Inmate Employer", `${this._detailsObject}.Employer`));
                break;
            case bondWorkFlow.property.string:
                columns.push(createColumn("Owner Name(s)", `${this._detailsObject}.PropertyOwnerNames`));
                columns.push(createColumn("Parcel Number", `${this._detailsObject}.ParcelNumber`));
                columns.push(createColumn("Available Collateral", `${this._detailsObject}.AvailableCollateral`, undefined, dollarFieldFormatter));
                break;
            case bondWorkFlow.ownerSurety.string:
                columns.push(createColumn("Bond Fee", `${this._detailsObject}.CountyBondFee`, undefined, dollarFieldFormatter));
                break;
            case bondWorkFlow.surety.string:
                columns.push(createColumn("Bond Fee", `${this._detailsObject}.CountyBondFee`, undefined, dollarFieldFormatter));
                columns.push(createColumn("POA Number", `${this._detailsObject}.POANumber`));
                columns.push(createColumn("POA Limit", `${this._detailsObject}.POALimit`, undefined, dollarFieldFormatter));
                columns.push(createColumn("POA Status", `${this._detailsObject}.POAStatus`));
                break;
        }

        if (this._type === bondWorkFlow.cash.string || this._type === bondWorkFlow.pr.string || this._type === bondWorkFlow.property.string) {
            table.bootstrapTable({
                columns: columns,
                data: model,
                classes: "table table-sm table-striped table-bordered",
                detailView: true,
                detailFormatter: this._getDetailFormatter(),
                uniqueId: "BondApplicationDetailID"
            });
        } else {
            table.bootstrapTable({
                columns: columns,
                data: model,
                classes: "table table-sm table-striped table-bordered",
                uniqueId: "BondApplicationDetailID"
            });
        }
    }

    _getDetailFormatter() {
        if (this._type === bondWorkFlow.cash.string)
            return this._generateCashDetailViewTable.bind(this);
        else if (this._type === bondWorkFlow.property.string)
            return this._generatePropertyDetailViewTable.bind(this);
        else
            return this._generatePrDetailViewTable.bind(this);
    }

    _generateCashDetailViewTable(index, row, element) {
        let columns = [];
        columns.push(createColumn("Payor Name", "PayorName"));
        columns.push(createColumn("Payor Address", "PayorAddress"));
        columns.push(createColumn("Payor City", "PayorCity"));
        columns.push(createColumn("Payor State", "PayorState"));
        columns.push(createColumn("Payor Zip", "PayorZip"));
        columns.push(createColumn("Payor Phone", "PayorPhone"));
        columns.push(createColumn("Cash Amount", "CashAmt", undefined, dollarFieldFormatter));
        const detailRequestUrl = `/Application/CashDetails/${row.BondApplicationDetailID}`;
        generateAndShowDetailTable(columns, detailRequestUrl, element);
    }

    _generatePropertyDetailViewTable(index, row, element) {
        const columns = [];
        columns.push(createColumn("Address", "Address"));
        columns.push(createColumn("City", "City"));
        columns.push(createColumn("State", "State"));
        columns.push(createColumn("Zip", "Zip"));
        const detailRequestUrl = `/Application/PropertyDetails/${row.BondApplicationDetailID}`;
        generateAndShowDetailTable(columns, detailRequestUrl, element);
    }

    _generatePrDetailViewTable(index, row, element) {

        let columns = [];
        columns.push(createColumn("Relative Address", "NearestRelativeAddress"));
        columns.push(createColumn("Relative City", "NearestRelativeCity"));
        columns.push(createColumn("Relative State", "NearestRelativeState"));
        columns.push(createColumn("Relative Zip", "NearestRelativeZip"));
        columns.push(createColumn("Relative Phone", "NearestRelativePhone"));
        columns.push(createColumn("Inmate Employer Address", "EmployerAddress"));
        columns.push(createColumn("Inmate Employer City", "EmployerCity"));
        columns.push(createColumn("Inmate Employer State", "EmployerState"));
        columns.push(createColumn("Inmate Employer Zip", "EmployerZip"));
        columns.push(createColumn("Inmate Employer Phone", "EmployerPhone"));
        const detailRequestUrl = `/Application/PrDetails/${row.BondApplicationDetailID}`;
        generateAndShowDetailTable(columns, detailRequestUrl, element);
    }

    _setToolTipAndLegaleseForSuretyTypes(countyBondFee, totalBondAmt, ebcaFee, bondApplicationFees, collateralAmount) {
        const toolTipModifier = this._hasFees(countyBondFee, ebcaFee) ? "proceed to the fee payment page." : "send the application to the review queue.";

        this._titleElement.innerHTML =
            `Complete ${this._bondTypeDisplayName} Bond Application <span id="title-tooltip" class="fa fa-info-circle" data-toggle="tooltip" title="Verify the bond ` +
            `application information is correct and click the ${this._agreeButtonText.textContent} button to ${toolTipModifier}"></span>`;

        $(this.shadowRoot.getElementById("title-tooltip")).tooltip({ boundary: "window", placement: "auto" });
        this._legalese.innerHTML = `I, <strong>${this._username}</strong> as an employee of <strong>${this._userBondCompany}</strong>, ` +
            `do swear upon submission of this form that said bond company is held and firmly bound unto the State of ${this._tenantStateFullName} the penal sum ` +
            `of ${this._calculateFeeText(bondApplicationFees.filter(x => x.IsSurcharge), totalBondAmt)} and that we are worth in our own right, at least the sum of ` +
            `<strong>${this._pageTools.formatNumberToDollarAmount(collateralAmount)}</strong> dollars, ` +
            `after deducting from our property all that which is exempt by the Constitution and Laws of the State from forced sale, and after the payment of all our debts, of every ` +
            `description, whether individual or security debts, and after satisfying all encumbrances upon our property which are known to us; and that we reside ` +
            `in <strong>${this._tenantName}</strong> and have property in the State liable to execution worth <strong>${this._pageTools.formatNumberToDollarAmount(collateralAmount)}</strong>.` +
            `${this._bondApplicationFeeLegalese(bondApplicationFees, false)}` +
            `<br/><br/>I further attest that my name is: <strong>${this._username}</strong>.`;

        this._convenienceFeeWarning.toggleAttribute("hidden", !this._hasConvenienceFee);
        this._paymentGatewayNameElement.innerText = this._tenantName;
    }

    _setToolTipAndLegaleseForCompanyAttorney(countyBondFee, totalBondAmt, ebcaFee, bondApplicationFees) {
        const toolTipModifier = this._hasFees(countyBondFee, ebcaFee) ? "proceed to the fee payment page." : "send the application to the review queue.";

        this._titleElement.innerHTML =
            `Complete ${this._bondTypeDisplayName} Bond Application <span id="title-tooltip" class="fa fa-info-circle" data-toggle="tooltip" title="Verify the bond ` +
            `application information is correct and click the ${this._agreeButtonText.textContent} button to ${toolTipModifier}"></span>`;
        $(this.shadowRoot.getElementById("title-tooltip")).tooltip({ boundary: "window", placement: "auto" });

        this._legalese.innerHTML =
            `I, <strong>${this._username}</strong>, acting on behalf of <strong>${this._userBondCompany}</strong>, ` +
            `agree to ${this._calculateFeeText(bondApplicationFees.filter(x => x.IsSurcharge), totalBondAmt)} set by <strong>${this._tenantName}</strong>.` +
            `${this._bondApplicationFeeLegalese(bondApplicationFees, false)} <br/><br/>` +
            `${this._formatTenantLegaleseText()}` + `<br/><br/>I further attest that my name is: <strong>${this._username}</strong>.`;
    }

    _formatTenantLegaleseText() {
        if (this._tenantLegalese) {
            return `${this._tenantLegalese}`;
        }
        return `By signing this document, the applicant certifies that they are actively engaged in the practice of law, are a member of the State Bar of ${this._tenantStateFullName},
        and that this bail will be posted for a person they genuinely represent in a criminal case. The applicant further certifies that the attorney-client relationship was
        established under conditions that do not violate the canons of ethics or the published rules and regulations of the State Bar of ${this._tenantStateFullName}.`;
    }

    _bondApplicationFeeLegalese(bondApplicationFees) {
        if (bondApplicationFees.length > 0) {
            bondApplicationFees = bondApplicationFees.filter(x => !x.IsSurcharge);
            let feeText =
                    `<br/><br/>I further agree to submit for payment ${this._calculateFeeText(bondApplicationFees, 0)}`;
            
            if (this.hasConvenienceFee) {
                feeText += "<sup>*</sup>";
            }
            return feeText + ".";
        } else return "";
    }

    _setToolTipAndLegaleseForCountyTypes(totalBondAmount, bondAppFees, bondAmountModifier, collateralAmount) {
        this._titleElement.innerHTML = `Complete ${this._bondTypeDisplayName} Bond Application <span id="title-tooltip" class="fa fa-info-circle" data-toggle="tooltip" title="Verify the bond ` +
            `application information is correct and click the ${this._agreeButtonText.textContent} button to send the application to the review queue."></span>`;

        $(this.shadowRoot.getElementById("title-tooltip")).tooltip({ boundary: "window", placement: "auto" });

        let bondLegaleseTemplate = `I, <strong>${this._username}</strong> as an employee of <strong>${this._userBondCompany}</strong>, do swear upon submission of this form that `;
        let feeLegaleseTemplate = "I do swear upon submission of this form that said Payor(s) listed above have ";
        const nameLegalese = `I further attest that my name is <strong>${this._username}</strong>.`;

        switch(this._type) {
            case bondWorkFlow.pr.string:
                bondLegaleseTemplate += `the above Inmate has been authorized for release on ${this._bondTypeDisplayName}.<br/><br/>`;
                this._legalese.innerHTML = bondLegaleseTemplate + nameLegalese;
                break;
            case bondWorkFlow.property.string:
                bondLegaleseTemplate += `said Payor(s) listed above are held and firmly bound unto the State of ${this._tenantStateFullName} the penal sum of <strong>${this._pageTools.formatNumberToDollarAmount(totalBondAmount) }</strong> on behalf of the above Inmate.<br/><br/>`;
                feeLegaleseTemplate += `demonstrated ownership of, and placed as collateral, land with an available collateral equal to or above the amount of <strong>${this._pageTools.formatNumberToDollarAmount(collateralAmount) }</strong> for the Bond Amount on behalf of the above Inmate.<br/><br/>`;
                this._legalese.innerHTML = bondLegaleseTemplate + feeLegaleseTemplate + nameLegalese;
                break;
            default:    
                bondLegaleseTemplate += `said Payor(s) listed above are held and firmly bound unto the State of ${this._tenantStateFullName} the penal sum of <strong>${this._pageTools.formatNumberToDollarAmount(totalBondAmount)}</strong> on behalf of the above Inmate.<br/><br/>`;
                feeLegaleseTemplate += `paid ${this._calculateFeeText(bondAppFees, totalBondAmount * bondAmountModifier)} on behalf of the above Inmate.<br/><br/>`;
                this._legalese.innerHTML = bondLegaleseTemplate + feeLegaleseTemplate + nameLegalese;
        }
        this._convenienceFeeWarning.toggleAttribute("hidden", true);
    }

    _computeCollateralAmount(value) {
        switch(this._type) {
            case bondWorkFlow.ownerSurety.string:
                return (value.Bond.TotalBondAmt +
                        value.Bond.BondApplicationFees.filter(x => x.IsSurcharge).reduce((a, b) => a + b.Amount, 0)) *
                    this._collateralAmount;
            case bondWorkFlow.surety.string:
            case bondWorkFlow.companyAttorney.string:
                return value.Bond.TotalBondAmt +
                    value.Bond.BondApplicationFees.filter(x => x.IsSurcharge).reduce((a, b) => a + b.Amount, 0);
            case bondWorkFlow.property.string:
                return value.CountyDetails.reduce((acc, cv) => acc +
                    ((cv.BondAmt * cv.PercentSubtotal + cv.CountySurchargeFees) * this._collateralAmount),
                    0);
            default:
                return -1;
        }
    }

    _hasFees(countyBondFee, ebcaFee) {
        const totalFees = parseFloat(countyBondFee) + parseFloat(ebcaFee);
        if (totalFees > 0) {
            this._bondHasFee = true;
            this._agreeButtonText.textContent = "Agree and Continue";
            return true;
        }
        return false;
    }

    _calculateFeeText(fees, bondAmount) {
        const surchargeFees = fees.filter(x => x.IsSurcharge);
        const nonSurchargeFees = fees.filter(x => !x.IsSurcharge);
        const feesText = this._createFeeText(surchargeFees, nonSurchargeFees, bondAmount);
        let bondAmountText = "";
      
        if (bondAmount)
            bondAmountText = `<strong>${this._pageTools.formatNumberToDollarAmount(bondAmount)}</strong> for the Bond Amount`;

        return (bondAmountText + feesText);
    }

    _createFeeText(surchargeFees, nonSurchargeFees, bondAmount) {
        if (surchargeFees.length === 0 && nonSurchargeFees.length === 0) {
            return "";
        }
        let surchargeText = null;
        let surchargeTotal = null;
        if (surchargeFees.length > 0) {
            const surchargeLastFeeIndex = surchargeFees.length - 1;
            const surchargeTotalItemCount = surchargeFees.length + (bondAmount !== undefined ? 1 : 0);
            surchargeFees.sort(this._feeCompare);
            surchargeText = surchargeFees.reduce(this._feeReduceToText.bind(this, surchargeLastFeeIndex, surchargeTotalItemCount, bondAmount), "");
            surchargeTotal = surchargeFees.reduce((a, b) => a + b.Amount, 0);
        }
        const lastFeeIndex = nonSurchargeFees.length - 1;
        const totalItemCount = nonSurchargeFees.length + (bondAmount !== undefined ? 1 : 0);
        // Sort fees A-Z, with EBCA last, then append total.
        nonSurchargeFees.sort(this._feeCompare);
        let text = nonSurchargeFees.reduce(this._feeReduceToText.bind(this, lastFeeIndex, totalItemCount, bondAmount), "");
        if (surchargeFees.length > 0 && nonSurchargeFees.length > 0 && text[0] === ",")
            text = text.slice(2);
        
        const total = nonSurchargeFees.reduce((a, b) => a + b.Amount, 0) + bondAmount;
        const surchargeSeperator = nonSurchargeFees.length > 0 ? "and " : "";

        if (surchargeFees.length > 0) {
            const subtotalText = surchargeFees.length > 0 && nonSurchargeFees.length > 0 ? `, subtotaling <strong>${this._pageTools.formatNumberToDollarAmount(surchargeTotal + bondAmount)}</strong>, ` : "";
            return surchargeText + subtotalText +
                surchargeSeperator + text + `, totaling <strong>${this._pageTools.formatNumberToDollarAmount(total + surchargeTotal)}</strong>`;
        }
        else
            return text + `, totaling <strong>${this._pageTools.formatNumberToDollarAmount(total)}</strong>`;
    }

    _feeCompare(first, second)  {
        if (first.FeeCategory === feeCategory.ebca.value)
            return 1;
        if (second.FeeCategory === feeCategory.ebca.value)
            return -1;
        return first.Name.localeCompare(second.Name);
    }

    _removeSuretyFees(fees)  {
        //remove EBCA, remove CountyBondFee, SuretyFee, keep the rest and display then each
        return fees.filter(fee => fee.FeeCategory !== feeCategory.suretyFee.value && fee.FeeCategory !== feeCategory.ebca.value && fee.IsSurcharge === false);
    }

    _removeIsSurchargeFees(fees)  {
        //remove EBCA, remove CountyBondFee, SuretyFee, keep the rest and display then each
        return fees.filter(fee => fee.IsSurcharge === false);
    }

    _feeReduceToText(lastFeeIndex, totalItemCount, bondAmount, cumulative, current, i) {
        if (i > 0 || bondAmount !== undefined) { // We have an item before us
            if (totalItemCount >= 3 && i !== 0 || bondAmount !== 0) {
                cumulative += ", ";
            }

            if (i === lastFeeIndex && i !== 0) {
                if (i === 0) { // no preceding comma + space, lets add a space.
                    cumulative += " ";
                }
                cumulative += "and ";
            }
        }
        const percentTextModifier = current.Name.includes("%") ?  "" : "the";
        return cumulative + `<strong>${this._pageTools.formatNumberToDollarAmount(current.Amount)}</strong> for ${percentTextModifier} ${current.Name}`;
    }

    _cancelButtonOnclick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl(`/Application/CancelApplication/${this._bondApplicationId}`);
    }

    _agreeButtonOnclick() {
        this._alertMessage.textContent = "";
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const xhrWrapper = new XhrWrapper();

        if (this._bondHasFee && (this._type === bondWorkFlow.ownerSurety.string || this._type === bondWorkFlow.surety.string || this._type === bondWorkFlow.companyAttorney.string)) {
            this._pageTools.redirectToUrl(`/Application/AuthorizePayment/${this._bondApplicationId}`);
            return;
        }
        if (this._type === bondWorkFlow.ownerSurety.string || this._type === bondWorkFlow.surety.string || this._type === bondWorkFlow.companyAttorney.string) {
            xhrWrapper.makeRequest(
                "POST",
                "/Application/SubmitSuretyApplication",
                { bondApplicationId: this._bondApplicationId },
                this._submitCallback.bind(this)
            );
        } else {
            xhrWrapper.makeRequest(
                "POST",
                "/Application/SubmitApplication",
                { bondApplicationId: this._bondApplicationId },
                this._submitCallback.bind(this)
            );
        }
    }

    _submitCallback(response, isSuccess) {
        if (!isSuccess) {
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            if (response === "All charges are not available.") {
                this._chargesUnavailableModal.open(this._bondApplicationId);
                return;
            }

            this._alertMessage.innerHTML = response;
            return;
        }

        let url = "/Roster";
        if (this._reviewApplication) {
            url = `/ReviewQueue/CompleteReview/${this._bondApplicationId}`;
        }

        this._pageTools.redirectToUrl(url);
    }

    _setAgreeButton(event) {
        const error = this._errorMessage && this._errorMessage !== "";

        if (error) {
            this._agreeButton.toggleAttribute("disabled", true);
            return;
        }

        if (event) {
            this._agreeButton.toggleAttribute("disabled", !event.target.checked);
            return;
        }

        this._agreeButton.toggleAttribute("disabled", this._bondCompanyMugshotVerifyFeatureFlag);
    }

    _setAlertMessage() {
        if (this._errorMessage === null || this._errorMessage === "")
            return;

        this._alertMessage.textContent = this._errorMessage;
        this._setAgreeButton();
    }

    _setConfirmationCheckboxState() {
        if (this._bondCompanyMugshotVerifyFeatureFlag && !this._hasHideMugshotsForCompanyAccounts) {
            this._confirmationCheckboxContainer.toggleAttribute("hidden", false);
            this._confirmationCheckboxContainer.toggleAttribute("disabled", this._errorMessage && this._errorMessage !== "");
            this._setAgreeButton();
        }
        else {
            this._confirmationCheckboxContainer.toggleAttribute("hidden", true);
            this._agreeButton.toggleAttribute("disabled", false);
        }
    }
}

customElements.define("complete-application", CompleteApplication);
