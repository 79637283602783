import bootstrap from './../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import { clearAlert, showAlert } from './EditDialogExtensions';
import './InmateInformation'
import './ActionButton';
import paymentFeeDestination from './Enumerations/PaymentFeeDestination';
import template from './CaptureFees.html';
import applicationStatus from './Enumerations/ApplicationStatus';

class CaptureFees extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome]);
        this._pageTools = new PageTools();
        this._inmateInformation = this.shadowRoot.getElementById('inmate-information');
        this._totalBondAmount = this.shadowRoot.getElementById('total-bond-amount');
        this._totalCountyBondFees = this.shadowRoot.getElementById('total-county-bond-fees');
        this._remittanceId = this.shadowRoot.getElementById('remittance-id');
        this._paymentAuthorizationDate = this.shadowRoot.getElementById('payment-authorization-date');
        this._form = this.shadowRoot.getElementById('capture-fee-form');
        this._bondApplicationIdInput = this.shadowRoot.getElementById('bond-application-id-input');
        this._redirectLocation = this.shadowRoot.getElementById('redirect-location-id-input');
        this._returnButton = this.shadowRoot.getElementById('return-button');
        this._captureButton = this.shadowRoot.getElementById('capture-button');
        this._alert = this.shadowRoot.getElementById('alert-view');
        this._tooltip = this.shadowRoot.getElementById('title-tooltip');

        this._returnUrl = null;

        this._returnButtonOnclick = this._returnButtonOnclick.bind(this);
        this._captureButtonOnclick = this._captureButtonOnclick.bind(this);

    }

    set alert(value) {
        if (value) {
            showAlert(this._alert, value);
        }
    }

    get _userIsCounty() {
        return this.hasAttribute('user-is-county') &&
            this.getAttribute('user-is-county').toLowerCase() === 'true';
    }
    get _hasHideMugshotsForJailAccounts() {
        return this.hasAttribute('has-hide-mugshot-for-jail-accounts') &&
            this.getAttribute('has-hide-mugshot-for-jail-accounts').toLowerCase() === 'true';
    }

    set stateSubdivision(value) {
        $(this._tooltip).attr('data-original-title', `This bond application has pending fees. To proceed, the ${value.toLowerCase()} bond fees must be captured.`);
    }

    connectedCallback() {
        $(this._tooltip).tooltip({ boundary: 'window', placement: 'auto' });
        this._returnButton.addEventListener('click', this._returnButtonOnclick);
        this._captureButton.addEventListener('click', this._captureButtonOnclick);
        this._inmateInformation.userIsCounty = this._userIsCounty;
        this._inmateInformation.hasHideMugshotsForJailAccounts = this._hasHideMugshotsForJailAccounts;
    }

    disconnectedCallback() {
        this._returnButton.removeEventListener('click', this._returnButtonOnclick);
        this._captureButton.removeEventListener('click', this._captureButtonOnclick);
    }

    set data(value) {
        this._data = value;
        this._setActionsBasedOnBondStatus(value.BondApp.AppStatus);     
        const countyPaymentObj = value.BondApp.Payments.find(x => x.PaymentFeeDestination === paymentFeeDestination.county.value) || {};
        const countyFee = countyPaymentObj.Amount || 0;
        this._inmateInformation.loadInmateInformationByBondApplicationId(value.BondApp.BondApplicationID);
        this._totalBondAmount.value = `${this._pageTools.formatNumberToDollarAmount(value.BondApp.TotalBondAmt)}`;
        this._totalCountyBondFees.value = `${this._pageTools.formatNumberToDollarAmount(countyFee)}`;
        this._remittanceId.value = value.BondApp.RemittanceID;
        this._paymentAuthorizationDate.value = window.DateFormatter.utcToLocal(countyPaymentObj.AuthorizedDateTime);
        this._bondApplicationIdInput.value = value.BondApp.BondApplicationID;
    }

    _setActionsBasedOnBondStatus(bondStatus) {
        if (bondStatus === applicationStatus.submitted.string) {
            this._returnUrl = '/ReviewQueue';
            this._captureButton.textContent = 'Capture Fees and Finish Review';
            this._form.action = '/ReviewQueue/CaptureFee';
        } else {
            this._returnUrl = '/BookOutQueue';
            this._captureButton.textContent = 'Capture Fees and Continue Bookout';
            this._form.action = '/BookOutQueue/CaptureFee';
        }
    }

    _returnButtonOnclick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl(`${this._returnUrl}`);
    }

    _captureButtonOnclick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        clearAlert(this._alert);
        this._form.submit();
    }
}
customElements.define('capture-fees', CaptureFees)