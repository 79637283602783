// Generated By NTypeWriter Visual Studio Extension and TypescriptTemplate.nt
// Tag types with [ExportToTypescript] attribute to generate these files, then build the solution

export enum FeatureFlag {
  UseFingerPrintScanner = 0,
  UseDemoFeatures = 1,
  BookoutCompleteShowsAllCharges = 2,
  PowersOfAttorneyReport = 3,
  PoaBanner = 4,
  PRBondStrictValidation = 5,
  ContinueOnSyncFailure = 6,
  CourtInformationHasAgencyCaseNumber = 7,
  RestrictCardsTemp = 8,
  BondCompaniesHaveFullRosterAccess = 9,
  AcceptsAllPaidPayments = 10,
  CountyUserHasAllPaidNavMenuItem = 11,
  HasMugshot = 12,
  HasPerOffenseFee = 13,
  BondTypeSurchargeScheduleTemp = 14,
  CourtInformationRequiredForCountyUsers = 15,
  CourtInformationOptionalForCountyUsers = 16,
  CourtInformationRequiredForBondUsers = 17,
  CourtInformationOptionalForBondUsers = 18,
  OffensesHaveSpecialConditions = 19,
  PrBondHasIssuingAuthority = 20,
  HaysConfirmNoHoldsTemp = 21,
  OutOfCountyBondTemplateTemp = 22,
  DetailedManageUsersTableTemp = 23,
  BondDocumentTemplatesAreAssignablePerOffense = 24,
  ApplicationRefreshTemp = 25,
  BondTypeEnforcementTemp = 26,
  ReceiptTemplateUploadTemp = 27,
  UserHasEmployerIdTemp = 28,
  ExtraditionInformationTemp = 29,
  BondCompanyMugshotVerify = 30,
  SendEmailToBondCompanyOnApplicationSubmitted = 31,
  SendEmailToBondCompanyOnApplicationReviewed = 32,
  AllPaidDisablePartialPayments = 33,
  PreventBondCompaniesFromReceivingOrViewingBondDocuments = 34,
  CourtInformationCauseNumberRequired = 35,
  CourtInformationWarrantNumberRequired = 36,
  CaptureFeesAtEndOfReviewProcess = 37,
  AttorneyBarNumberExpirationRequired = 38,
  PreviewAllFeatureFlagTemp = 39,
  HasBookingSheet = 40,
  ReviewAuthorizationRequiredTemp = 41,
  FullApiInmateAccess = 42,
  RefreshOnCadence = 43,
  TenantWidePoaReportTemp = 44,
  EnforceBondApplicationLimitsTemp = 45,
  BondDocumentCertificationTemp = 46,
  ShortcutBookoutQueueProcessTemp = 47,
  RequireAuthorizationSignatureAtBookout = 48,
  BondCompaniesOnlyGetUnofficialBondDocuments = 49,
  ExpandUserRolesTemp = 50,
  ConfigurableTimeoutTemp = 51,
  SingleSessionSignOnTemp = 52,
  RestrictIssuingAuthority = 53,
  CollateralLimitEnforcement = 54,
  OffensesHaveVerboseSpecialConditions = 55,
  TotalBonds = 56,
  UserLevelPoaLicense = 57,
  HideMugshotsForJailAccounts = 58,
  HideMugshotsForCompanyAccounts = 59,
  HideMugshotsForPaymentPortal = 60,
  ShowPaymentMethod = 61,
  StoreCompaniesAssignedToUserInDatabaseTemp = 62,
  AllowPaymentPortalInmateReleaseNotification = 63,
  PaymentPortalPlcCheckTemp = 64,
  NotifyTenantOfDataProcessingIssuesTemp = 65,
  PaymentPortalRequiresPayorIdentifyingInformation = 66,
  ShowBookingReportOfficialUseOnlyText = 67,
  DataEntryErrorLogicTemp = 68,
  UpdateFeeCalculationTemp = 69,
  ShowBookingReportSpecialConditions = 70,
  BondDocumentsBookedOutReportShowsCourtNameTemp = 71,
  RequireAdditionalAuthorizationSignatureAtBookout = 72,
  CourtNameShowsForBondApplication = 73,
  CourtInformationReadOnly = 74,
  EnforceOwnerBondCompanyCollateralLimit = 75,
  HideAdditionalInmateInformationInputs = 76,
  OptionalParcelNumber = 77,
  AttorneyCompaniesTemp = 78,
  ExtendedAllPaidValidationTemp = 79,
  BondPaymentCaptureCourtTemp = 80,
  CollectInmateContactInformation = 81,
  CollectInmateContactEmailAddress = 82,
  CollectInmateContactEmailAddressRequired = 83,
  SendEmailToJailUsersOnApplicationSubmittedOrReviewedTemp = 84,
  AllowAmendingBondDocuments = 85
}