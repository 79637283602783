import bootstrap from '../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap-table/dist/bootstrap-table';
import template from './BookOutQueueCompletedBookout.html';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { createColumn, dollarFieldFormatter, printPreviewPowerButtonFormatter, printPreviewButtonFormatter } from '../BootstrapTableExtensions';
import { showAlert } from './../EditDialogExtensions';
import '../InmateInformation';
import '../ActionButton';
import './BookOutQueuePendingChargesDialog';
import './BookOutQueueDocumentFailureDialog';
import { isAttributeTrue } from '../JavaScriptFunctions';

class BookOutQueueCompletedBookout extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ['container']);

        this._showAllCharges = null;
        this._additionalCharges = null;
        this._detailStatus = null;
        this._inmateFullName = null;
        this._pageTools = new PageTools();
        this._downloadAndAttachMessage = this.shadowRoot.getElementById('download-and-attach-message');
        this._inmateInformation = this.shadowRoot.getElementById('inmate-information');
        this._completedBondsTable = this.shadowRoot.getElementById('completed-bonds-table');
        this._pendingChargesDialog = this.shadowRoot.getElementById('book-out-queue-pending-charges-dialog');
        this._viewPendingChargesButton = this.shadowRoot.getElementById('view-pending-charges-button');
        this._viewDocuemntFailuresButton = this.shadowRoot.getElementById('view-document-failures-button');
        this._returnButton = this.shadowRoot.getElementById('return-button');
        this._bookoutQueueDocumentFailureDialog = this.shadowRoot.getElementById('book-out-queue-document-failure-dialog');
        this._printPreviewAllButton = this.shadowRoot.getElementById('print-preview-all-button');
        this._alert = this.shadowRoot.getElementById('alert-message');

        this._returnButtonOnClick = this._returnButtonOnClick.bind(this);
        this._printPreviewAllClick = this._printPreviewAllClick.bind(this);
        this._openPendingChargesDialog = this._openPendingChargesDialog.bind(this);
        this._openUploadFailuresDialog = this._openUploadFailuresDialog.bind(this);
        this._model = null;
    }

    set powersDocumentStorageTempFlagEnabled(value) {
        this._powersDocumentStorageTempFlagEnabled = value;
    }

    /**
     * @typedef {object} CompletedBookoutViewModel
     * @property {number} BondApplicationId
     * @property {BondInmateViewModel} Inmate
     * @property {CompletedBondApplicationDetailViewModel[]} CompletedBondDetails
     * @property {CompletedBookoutAdditionalChargesViewModel[]} AdditionalCharges
     * @property {boolean} HasHold
     */

    /**
     * @typedef {object} BondInmateViewModel
	 * @property {string} InmateFN
	 * @property {string} InmateMI
	 * @property {string} InmateLN
	 * @property {string} InmateFullName
	 * @property {string} InmateFullNameRev
	 * @property {string} DOB
	 * @property {string} Gender
	 * @property {number} HeightFt
	 * @property {number} HeightIn
	 * @property {string} Height
	 * @property {?number} Weight
	 * @property {string} Hair
	 * @property {string} EyeColor
	 * @property {string} Race
	 * @property {number} JailID
	 * @property {string} BookNumber
	 * @property {string} SONumber
	 * @property {string} SSN
	 * @property {string} ZipCode
	 * @property {string} State
	 * @property {string} City
	 * @property {string} Address
	 * @property {string} PhoneNumber
	 * @property {string} DLNumber
	 * @property {string} DLState
	 * @property {?number} PartyID
	 * @property {?number} PersonID
	 * @property {number} InmateID
	 * @property {string} SidNumber
	 * @property {string} FbiNumber
	 * @property {boolean} HasDataEntryErrors
     */

    /**
     * @typedef {Object} CompletedBondApplicationDetailViewModel
     * @property {number} BondApplicationDetailId
     * @property {string} InmateFullName
     * @property {?number} BondAmount
     * @property {number} CountyBondFee
     * @property {string} BondTypeDisplayName
     * @property {string} OffenseDegree
     * @property {string} OffenseDesc
     * @property {int} BondDocumentId
     * @property {?string} PowerDocument - Guid
     * @property {BondApplicationDetailFeeModel[]} BondApplicationDetailFees
     */

    /**
     * @typedef {object} BondApplicationDetailFeeModel
     * @property {number} Amount
     * @property {number} FeeCategory - enum FeeCategory
     * @property {string} Name
     * @property {boolean} IsSurcharge
     */

    /**
     * @typedef {object} CompletedBookoutAdditionalChargesViewModel
	 * @property {string} ArrestDate
	 * @property {string} OffenseCode
	 * @property {string} OffenseDesc
	 * @property {string} OffenseDegree
	 * @property {string} BondDesc
	 * @property {?number} BondAmt
	 * @property {string} BondType
	 * @property {string} ChargeStatus
     */

    /**
     * @param {CompletedBookoutViewModel} value
     */
    set model(value) {
        this._model = value;
        this._updateHtml(value);
        const completedBondsTableModel = value.CompletedBondDetails.map(bondDetails => ({
            BondApplicationDetailID: bondDetails.BondApplicationDetailId,
            CompletedBondDetails: bondDetails
        }));

        this._loadCompletedBondsTableData(completedBondsTableModel);
        
        if (value.CompletedBondDetails.some(x => x.ShouldHavePowersDocument && !x.PowerDocument)) {
            this._viewDocuemntFailuresButton.toggleAttribute('hidden', false);
            this._detailStatus = value.CompletedBondDetails.map(x => ({
                BondNumber: x.BondApplicationDetailId,
                OffenseDegree: x.OffenseDegree,
                OffenseDescription: x.OffenseDesc,
                PowersDocumentStored: !!x.PowerDocument
            }));
            this._bookoutQueueDocumentFailureDialog.openModal(this._detailStatus);
        }

        if (value.AdditionalCharges.length > 0 || value.HasHold) {
            this._additionalCharges = value.AdditionalCharges;
            this._inmateFullName = `${value.Inmate.InmateLN}, ${value.Inmate.InmateFN} ${value.Inmate.InmateMI}`;
            this._pendingChargesDialog.openModal(this._additionalCharges, this._inmateFullName, value.HasHold);
        } else {
            this._viewPendingChargesButton.toggleAttribute('hidden', true);
        }
    }

    set showAllCharges(value) {
        this._pendingChargesDialog.toggleAttribute('hidden', !value);
        this._viewPendingChargesButton.toggleAttribute('hidden', !value);
    }

    connectedCallback() {
        this._returnButton.addEventListener('click', this._returnButtonOnClick);
        this._viewPendingChargesButton.addEventListener('click', this._openPendingChargesDialog);
        this._viewDocuemntFailuresButton.addEventListener('click', this._openUploadFailuresDialog);
        this._printPreviewAllButton.addEventListener('click', this._printPreviewAllClick);
        this._printPreviewAllButton.toggleAttribute('hidden', !isAttributeTrue(this, "preview-all-feature-flag-temp"));
        this._inmateInformation.userIsCounty = isAttributeTrue(this, "user-is-county");
        this._inmateInformation.hasHideMugshotsForJailAccounts = isAttributeTrue(this, "has-hide-mugshot-for-jail-accounts");
    }

    disconnectedCallback() {
        this._returnButton.removeEventListener('click', this._returnButtonOnClick);
        this._viewPendingChargesButton.removeEventListener('click', this._openPendingChargesDialog);
        this._viewDocuemntFailuresButton.removeEventListener('click', this._openUploadFailuresDialog);
        this._printPreviewAllButton.removeEventListener('click', this._printPreviewAllClick);
    }

    _updateHtml(model) {
        this._inmateInformation.loadInmateInformationByBondApplicationId(model.BondApplicationId);
    }

    _loadCompletedBondsTableData(model) {
        const completedBondsTable = $(this._completedBondsTable);
        const completedBondsTableColumns = [];
        completedBondsTableColumns.push(createColumn('Bond Number', 'CompletedBondDetails.BondApplicationDetailId'));
        completedBondsTableColumns.push(createColumn('Degree', 'CompletedBondDetails.OffenseDegree'));
        completedBondsTableColumns.push(createColumn('Offense', 'CompletedBondDetails.OffenseDesc'));
        completedBondsTableColumns.push(createColumn('Bond Type', 'CompletedBondDetails.BondTypeDisplayName'));
        completedBondsTableColumns.push(createColumn('Bond Amount', 'CompletedBondDetails.BondAmount', undefined, dollarFieldFormatter));

        if (model.some(item => parseFloat(item.CompletedBondDetails.CountyBondFee) >= 0 || item.CompletedBondDetails.CountyBondFee)) {
            completedBondsTableColumns.push(createColumn('Bond Fee', 'CompletedBondDetails.CountyBondFee', undefined, dollarFieldFormatter));
        }

        completedBondsTableColumns.push(createColumn('Posted Bond Document', 'CompletedBondDetails.BondDocumentId', false, printPreviewButtonFormatter));
        completedBondsTableColumns.push(createColumn('Electronic Power Document', 'CompletedBondDetails.PowerDocument', false, printPreviewPowerButtonFormatter));

        completedBondsTable.bootstrapTable({
            columns: completedBondsTableColumns,
            data: model,
            classes: 'table table-sm table-striped table-bordered',
            uniqueId: 'BondApplicationDetailID'
        });
    }

    _openPendingChargesDialog() {
        this._pendingChargesDialog.openModal(this._additionalCharges, this._inmateFullName);
    }

    _openUploadFailuresDialog() {
        this._bookoutQueueDocumentFailureDialog.openModal(this._detailStatus);
    }

    _returnButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl('/BookOutQueue');
    }

    _printPreviewAllClick() {
        const bondDocumentIds = this._model.CompletedBondDetails.map(x => x.BondDocumentId);
        this._pageTools.toggleTriggers(this.shadowRoot, true);

        const data = {
            ids: bondDocumentIds,
            inmateFirstName: this._model.Inmate.InmateFN,
            inmateLastName: this._model.Inmate.InmateLN
        };
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", "/BookOutQueue/PrintAllBonds/", data, this._pageTools.openPdfXhrCallback.bind(this._pageTools, this.shadowRoot, showAlert.bind(this, this._alert)));
    }
}

customElements.define('book-out-queue-completed-bookout', BookOutQueueCompletedBookout);
