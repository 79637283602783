import { dollarFieldFormatter } from '../BootstrapTableExtensions.js';
import { initializeHtmlElement } from '../HTMLElementExtensions';

class ReviewQueueGrid extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-application-grid id="review-queue"></bootstrap-application-grid>',
            [],
            []
        );
        this._tableColumns = [];
        this._dynamicColumns = [];
        this._tableConfig = {};
        this._bootstrapApplicationGrid = this.shadowRoot.querySelector('bootstrap-application-grid');
        this._pageTools = new PageTools();

        this._reviewButtonOnClick = this._reviewButtonOnClick.bind(this);
        this._cancelButtonOnClick = this._cancelButtonOnClick.bind(this);
    }

    get _reviewApplicationFlag() {
        return this.hasAttribute('review-Application-flag') &&
            this.getAttribute('review-Application-flag').toLowerCase() === 'true';
    }

    get _cancelApplicationFlag() {
        return this.hasAttribute('cancel-Application-flag') &&
            this.getAttribute('cancel-Application-flag').toLowerCase() === 'true';
    }

    get _applicationRefreshTempFlag() {
        return this.hasAttribute('application-refreshTemp-flag') &&
            this.getAttribute('application-refreshTemp-flag').toLowerCase() === 'true';
    }

    connectedCallback() {
        this._compileColumns();
        this._createTableConfig();
        this._bootstrapApplicationGrid.initBootstrapApplicationGrid(this._tableConfig);
        this._refreshEnabled();
    }

    _reviewFormatter(value, row, index, field) {
        return `<div class="d-flex justify-content-center"><action-button class="btn btn-primary" id="review" trigger review>Review</action-button></div>`;
    }

    _cancelFormatter(value, row, index, field) {
        return `<div class="d-flex justify-content-center"><action-button class="btn btn-danger" id="cancel" trigger cancel>Cancel</action-button></div>`;
    }

    _compileColumns() {
        this._tableColumns = [{
                title: 'Inmate',
                field: 'InmateFullNameRev',
                class: 'InmateFullNameRev',
                type: 'Text',
                escape: true
            },
            {
                title: 'SO Number',
                field: 'SONumber',
                class: 'SONumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Location',
                field: 'LocationNode',
                class: 'LocationNode',
                type: 'Text',
                escape: true
            },
            {
                title: 'Booking Number',
                field: 'BookNumber',
                class: 'BookNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Bond Type',
                field: 'BondTypeDisplayName',
                class: 'BondTypeDisplayName',
                type: 'Text',
                escape: true,
                formatter: this._bootstrapApplicationGrid.bondTypeFormatter.bind(this)
            },
            {
                title: 'Total Bond Amount',
                field: 'TotalBondAmt',
                class: 'TotalBondAmt',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Total Bond Fees',
                field: 'TotalCountyBondFees',
                class: 'TotalCountyBondFees',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Time Stamp',
                field: 'TimeStamp',
                class: 'TimeStamp',
                type: 'Text',
                escape: true,
                formatter: this._bootstrapApplicationGrid.dateFormatter
            },
            {
                title: 'User Name',
                field: 'UserName',
                class: 'UserName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Bond Company',
                field: 'BondCompanyName',
                class: 'BondCompanyName',
                type: 'Text',
                escape: true
            },
            {
                title: 'JailID',
                field: 'JailID',
                class: 'JailID d-none',
                type: 'Number',
                escape: true,
                printIgnore: true
            },
            {
                title: 'BondApplicationID',
                field: 'BondApplicationID',
                class: 'BondApplicationID d-none',
                type: 'Number',
                escape: true,
                printIgnore: true
            },
            {
                title: '',
                field: "reviewPlaceholder",
                class: this._reviewApplicationFlag ? '' :'d-none',
                type: 'SafeHTML',
                searchable: false,
                escape: false,
                formatter: this._reviewFormatter,
                events: { 'click [review]': this._reviewButtonOnClick },
                printIgnore: true
            },  
            {
                title: '',
                field: "reviewCancelPlaceholder",
                class: this._cancelApplicationFlag ? '' : 'd-none',
                type: 'SafeHTML',
                searchable: false,
                escape: false,
                formatter: this._cancelFormatter,
                events: { 'click [cancel]': this._cancelButtonOnClick },
                printIgnore: true
            }
        ];
    }

    _createTableConfig() {
        this._tableConfig = {
            uniqueId: 'BondApplicationID',
            tableColumns: this._tableColumns,
            print: true,
            export: true,
            pageSize: 20,
            detailView: false,
            mainTableHeight: 600,
            exportFilename: 'Review-Queue',
            url: '/ReviewQueue/SubmitQ_Read',
            httpMethod: 'GET',
            restoreFilters: true,
            tableName: 'ReviewQueue',
            tableClasses: 'table-striped',
            paginationDetails: true,
            toolbar: true,
            tableAlertMessage: true,
            columnFilters: true,
            sortable: true
        };
    }

    _refreshEnabled() {
        if (this._applicationRefreshTempFlag) {
            this._bootstrapApplicationGrid.triggerRefresh();
        }
    }

    _reviewButtonOnClick(e, value, row, index) {
        this._pageTools.toggleTriggers(this._bootstrapApplicationGrid._bootstrapTable.shadowRoot, true);
        this._pageTools.redirectToUrl(`/ReviewQueue/ProcessSubmit/${row.BondApplicationID}`);
    }

    _cancelButtonOnClick(e, value, row, index) {
        this._pageTools.toggleTriggers(this._bootstrapApplicationGrid._bootstrapTable.shadowRoot, true);
        this._pageTools.redirectToUrl(`/Cancel/FinishCancel/${row.BondApplicationID}`);
    }
}

customElements.define('review-queue-grid', ReviewQueueGrid);